import { addBooking } from '../Firebase';
import { addLocalTripId } from '../Core/StorageUtils';

export const BOOKING_FORM_SET = 'BOOKING_FORM_SET';
export const BOOKING_FORM_RESET = 'BOOKING_FORM_RESET';

export const setForm = (key, value) => (dispatch, getState) => Promise.resolve(dispatch(_setForm(key,value)));

export const resetForm = () => (dispatch, getState) => dispatch(_resetForm());

const _setForm = (key, value) => ({
    type: BOOKING_FORM_SET,
    payload: {
        key, 
        value,
    }
});

const _resetForm = () => ({
    type: BOOKING_FORM_RESET,
    payload: {
    }
});
