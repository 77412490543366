import React, { Component, Fragment } from 'react';
const moment = require('moment');
import {isClip, overTime} from '../Core/AppUtils';
import {I18nCalendar} from '../Core/Constants';

const roundMinute = min => (min/5|0)*5;
export class TimePicker extends Component {
	constructor(props) {
        super(props);
        const {selectedDateTime} = props;
        const thisTime = selectedDateTime ? moment(selectedDateTime, I18nCalendar.formatDateTime) : moment().startOf('day');
        this.state = {
            hour: thisTime.hour(),
            minute: roundMinute(thisTime.minute()),
        };
        [
            'onSelect',
        ].forEach(fn => this[fn] = this[fn].bind(this));
    }
    onSelect(key) {
        return (value) => {
            this.setState((prevState, props)=>({
                [key]: value,
            }),()=>{
                const {onChange} = this.props;
                onChange && onChange(moment().set({
                    hour: this.state.hour,
                    minute: this.state.minute,
                }))
            });
        }
    }
    render() {
        const {hour, minute} = this.state;
    	return (
            <div className={`time-picker`}>
                <div className='current-time'>
                </div>
                <div className='time-picker__table-header'>
                    <b>Giờ : </b>
                    <i>Từ 23h-5h, tính phí ngoài giờ</i>
                </div>
                <Hours selectedHour={hour} onChange={this.onSelect('hour')} onDayNightChange={this.onDayNightChange}/>
                <p></p>
                <div className='time-picker__table-header'>
                    <b>Phút : </b>
                </div>
                <Minutes selectedMinute={minute} onChange={this.onSelect('minute')}/>
                <p></p>
            </div>
		)
    }
}

const Hours = ({selectedHour, onChange}) => 
    <Fragment>
        <div className={`time-picker__table time-picker__hours`}>
            <label className='time-picker__table-icon' htmlFor='day-night-switch'>
                <span id='day'></span> 
                <span id='night'></span> 
            </label>
            <div className='time-picker__table-content'>
                {[...Array(24).keys()].map(index=>(index+7)%24).map(hour=> 
                    <div className={`time-picker__table-item
                        ${overTime(hour)?'strong':''}
                        ${hour===moment().hour()?'now':''}
                        ${hour===selectedHour?'selected':''}`}
                        onClick={e=>onChange(hour)}
                        key={`HH-${hour}}`} >
                        {hour}
                    </div>
                )}
            </div>
        </div>
    </Fragment>

const Minutes = ({selectedMinute, onChange}) => 
    <div className={`time-picker__table time-picker__minutes`}>
        {[...Array(6).keys()].map(index=>index*10).map(minute=>
            <div className={`time-picker__table-item
                ${minute===roundMinute(moment().minute())?'now':''}
                ${minute===roundMinute(selectedMinute)?'selected':''}`}
                onClick={e=>onChange(minute)}
                key={`mm-${minute}}`} >
                :{minute}
            </div>
        )}
    </div>