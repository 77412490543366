//TODO : pass option to import, load current language from redux
const currentLanguage = 'vn';
export const translate = key => language[currentLanguage][key] || key;

const language = {
	vn : {},
	en : {
		'Đặt xe': '',
		'Lịch trình': '',
		'Lộ trình': '',
		'Dịch vụ': '',
		'Hoàn tất': '',
		'Điểm đón khách': '',
		'Điểm đến': '',
		'Thêm địa điểm': '',
		'Tiếp tục': '',
		'Ngày tháng': '',
		'Loại xe': '',
		'Chiều di chuyển': '',
		'Họ tên': '',
		'Số điện thoại': '',
		'Email': '',
		'Ảnh đại diện': 'Avatar',
		'Quay lại': '',
		'Đặt xe': '',
		'Chọn thời gian đón': '',
		'': '',
		'': '',
		'': '',
		'': '',
		'': '',
	}
}