import 'whatwg-fetch'

import { addBooking, removeBooking } from '../Firebase';
import { addLocalTripId, removeLocalTripId, setLocalTripIds } from '../Core/StorageUtils';

import { resetForm } from './BookingForms.action';

export const BOOKED_TRIP_ADD = 'BOOKED_TRIP_ADD';
export const BOOKED_TRIP_REMOVE = 'BOOKED_TRIP_REMOVE';
export const BOOKED_TRIP_SET = 'BOOKED_TRIP_SET';

export const addBookedTrip = () => (dispatch, getState) => {
    fetch('https://slack.com/api/chat.postMessage', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer xoxb-3496653787252-3518062879648-0AxS0uVTtmjEiVlrHc3mMEj8',
        },
        body: JSON.stringify({
            channel: "C03EFBMDWN9",
            blocks: [
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: `*Khách A* đặt chuyến \n*Hà Nội - Sóc Sơn* \nvào lúc *18h30p* ngày *20/06/2022* \n<tel:012345678|Tel: 012345678> ; <mailto:user@domain.com|email: test@test.com>`
                }
              }
            ]
        })
    })
    addBooking(getState().bookingForm).then(trip=> {
        dispatch(resetForm());
        dispatch(_addBookedTrip(trip));
        addLocalTripId(trip.id);
    });
};

export const removeBookedTrip = (trip) => (dispatch, getState) => {
    removeBooking(trip.id);
    dispatch(_removeBookedTrip(trip));
    removeLocalTripId(trip.id);
}

export const setBookedTrip = (trips) => (dispatch, getState) => {
    dispatch(_setBookedTrip(trips));
    setLocalTripIds(trips.map(e=>e.id));
};


const _setBookedTrip = (trips) => ({
    type: BOOKED_TRIP_SET,
    payload: {
        trips,
    }
});

const _addBookedTrip = (trip) => ({
    type: BOOKED_TRIP_ADD,
    payload: {
        trip,
    }
});

const _removeBookedTrip = (trip) => ({
    type: BOOKED_TRIP_REMOVE,
    payload: {
        trip,
    }
});
