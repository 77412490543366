import React, { Fragment } from 'react';

export const Popup = ({header, icon, children, togglePopup, showPopup}) => (
    <section className={`popup__overlay ${!showPopup? 'popup--hide' : ''}`} onClick={e=>{e.target.classList.contains('popup__overlay') && togglePopup()}}>
    	<article className='popup'>
    		<section className='popup__header'>
    			<div className='popup__title'> {header} </div>
    			<div className='popup__closer' onClick={e=>togglePopup()}></div>
    		</section>
    		<section className='popup__content'>
    			{children}
    		</section>
    		<section className='popup__footer'>
    		</section>
    	</article>
    </section>
)