import { BOOKING_FORM_SET, BOOKING_FORM_RESET } from '../Actions/BookingForms.action';
import { I18nCalendar, milestones, routeTypeOptions } from '../Core/Constants';
const moment = require('moment');

const defaultState = {
	listPoints: [null, milestones.NoibaiAirport],
	direction: '1-way',
	car: '5-seat',
	selectedDateTime: moment().add(5,'minutes').format(I18nCalendar.formatDateTime),
	name: '',
	tel: '',
	email: '',
	comment: '',
	avatar: '',
	flightCode: '',
	withBoardName: false,

	distance: 0,
	duration: 0,
	detail: [],
	price: 0,
	discount: 0,
	routeType: routeTypeOptions['hanoi-noibai'],
	tip: 0,
}
export const bookingForm = (state = defaultState, action) => {
	const {type, payload} = action;
	if (!payload) return state;
    switch (action.type) {
        case BOOKING_FORM_SET:
        	let {key, value} = payload;
            return {
                ...state,
                [key] : value,
            };
        case BOOKING_FORM_RESET: 
        	return {...defaultState}
        default:
            return state;
    }
}