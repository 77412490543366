import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { RippleButton } from '../Components/RippleButton';
import { Calendar } from '../Components/Calendar';
import { TimePicker } from '../Components/TimePicker';
import { I18nCalendar } from '../Core/Constants';
import { Popup } from '../Components/Popup';
import { translate } from '../Core/Translations';
const moment = require('moment');

export class DateTimeControl extends Component {
	constructor(props) {
		super(props);
		const {
			selectedDateTime = moment(),
		} = props;
		this.state = {
			showDate : false,
			showTime : false,
			selectedDateTime,
		};
		[
			'onChange',
			'toggleDate',
			'toggleTime',
		].forEach(fn => this[fn] = this[fn].bind(this));
		this.place = null;
	}

	onChange(field='date') {
		return date => {
			date = moment(date);
			let {selectedDateTime = moment()} = this.state;
			const value = (field==='date') ? {
					year: date.year(),
					month: date.month(),
					date: date.date(),
				} : {
					hour: date.hour(),
					minute: date.minute(),
				}  
			selectedDateTime = moment(selectedDateTime, I18nCalendar.formatDateTime).set(value).format(I18nCalendar.formatDateTime);
			this.setState((prevState, props)=>({
				selectedDateTime
			}), ()=> {
				if (field==='date') {
					this.toggleDate();
					this.onSubmit();
				} else {
					//this.toggleTime();
				}
			});
		}
	}

	onSubmit() {
		const {onChange} = this.props;
		const {selectedDateTime} = this.state;
		onChange && onChange(selectedDateTime);
	}

	toggleDate(e) {
		this.setState((prevState, props)=>({
			showDate : !prevState.showDate,
		}));
	}

	toggleTime(e) {
		this.setState((prevState, props)=>({
			showTime : !prevState.showTime,
		}));
	}

	componentDidMount() {
	}

	componentWillReceiveProps(nextProps) {
		this.setState((prevState, props)=> {
			let {selectedDateTime = moment().format(I18nCalendar.formatDateTime)} = this.props;
			return {selectedDateTime}
		});
	}

	render() {
		const { className, icon, placeholder, setForm} = this.props;
		const {value, showDate, showTime, switchDate, selectedDateTime} = this.state;
		return (
			<Fragment>
				<div className={`nb-form-control nb-form-control--date-time ${className||''}`}>
					{icon && 
						<i className={`nb-form-control__icon nb-form-control__icon--calendar`}></i>
					}
					<input
						type='text' placeholder={placeholder} 
						value={moment(selectedDateTime, I18nCalendar.formatDateTime).format(I18nCalendar.formatDate) || ''} 
						className='nb-form-control__input' 
						readOnly={true}
						onClick={e=>this.toggleDate(e)}/>
					{icon && 
						<i className={`nb-form-control__icon nb-form-control__icon--clock`}></i>
					}
					<input
						type='text' placeholder={placeholder} 
						value={moment(selectedDateTime, I18nCalendar.formatDateTime).format(I18nCalendar.formatHour) || ''} 
						className='nb-form-control__input' 
						readOnly={true}
						onClick={e=>this.toggleTime(e)}/>
				</div>
				<Popup header={translate('Chọn ngày đón')} togglePopup={this.toggleDate} showPopup={showDate}> 
					<Calendar selectedDateTime={selectedDateTime} onChange={this.onChange()}/>
					<ConfirmButton value={moment(selectedDateTime, I18nCalendar.formatDateTime).format(I18nCalendar.formatDate)} onClick={()=>{this.toggleDate();this.onSubmit()}}/>
				</Popup>
				<Popup header={translate('Chọn giờ đón')} togglePopup={this.toggleTime} showPopup={showTime}> 
					<TimePicker selectedDateTime={selectedDateTime} onChange={this.onChange('time')}/>
          <ConfirmButton value={moment(selectedDateTime, I18nCalendar.formatDateTime).format(I18nCalendar.formatHour)} onClick={()=>{this.toggleTime();this.onSubmit()}} />
				</Popup>
			</Fragment>
		)
	}
}

const ConfirmButton = ({value, onClick}) => 
	<div className='switch__confirm'>
	  <div className='button button--info' onClick={e=> onClick()}>
	      <RippleButton>
	          {value && 
	            <span>{value}</span>
	          }
	      </RippleButton>
	  </div>
	</div>