import {BOOKED_TRIP_ADD, BOOKED_TRIP_REMOVE, BOOKED_TRIP_SET} from '../Actions/BookedTrip.action';
const defaultState = {
	bookedTrips: [],
}
export const bookedTrips = (state = defaultState, action) => {
	const {type, payload} = action;
	if (!payload) return state;
	const {trips, trip} = payload;
    switch (action.type) {
        case BOOKED_TRIP_SET: 
            if (!trips || trips.length===0) return state;
            return {
                ...state,
                bookedTrips : [...trips]
            }
        case BOOKED_TRIP_ADD:
            if (!trip) return state;
            return {
                ...state,
                bookedTrips : [...state.bookedTrips, trip]
            };
        case BOOKED_TRIP_REMOVE:
            if (!trip) return state;
            return {
                ...state,
                bookedTrips : state.bookedTrips.filter(each=>trip.id!==each.id)
            };    
        default:
            return state;
    }
}