import React, { Fragment } from 'react';
import { RippleButton } from './RippleButton';
import { shortLocation } from '../Core/AppUtils';
const moment = require('moment');
const format = 'DD-MM-YYYY';

const CarReservationItem = ({item, active, selected, disabled, hideTaxi}) => !item ? null : 
    <Fragment>
        <div className='car-reservation__item-content'>
            <Avatar name={item.name} image={item.image} small={false}/>
            <strong> Khách : </strong>
            <span> {item.name} </span>
            
            {hideTaxi ? null :
                <Fragment>
                    <br/>
                    <strong> Tài xế : </strong>
                    <span> {item.driverName || 'Chưa ai nhận'} </span>
                </Fragment>
            }

            <br/>
            <strong> Vào lúc : </strong>
            <span> {item.selectedDateTime} </span>

            <br/>
            <strong> Đón ở : </strong>
            <span> {getFirstPoint(item.listPoints)} </span>
            
        </div>

        {/*<div className={`car-reservation__item-chip`}>
                    <span className='chip chip--car'>
                        {item.carType} 
                    </span>
                    <span className='chip chip--clock'>
                        {item.time}
                    </span>
                    <span className='chip chip--payment'>
                        {formatPrice(item.price)}
                    </span>
                    {!active && !selected ? '...' : 
                        <Fragment>
                            <span className='chip chip--repeat'>
                                {item.directionType}
                            </span>
                            <span className='chip chip--calendar'>
                                {moment(item.date).format(format)}
                            </span>
                            <span className='chip chip--direction'>
                                {formatDistance(item.distance)}
                            </span>
                            
                        </Fragment>
                    }
                </div>
                {!active && !selected ? null : 
                    <div className='button-group'>
                        <RippleButton href={`tel: ${item.tel}`} moreclass='button button--danger button--call'>
                            {item.tel.toLocaleString()}
                        </RippleButton>
                        {active ? 
                            <RippleButton moreclass='button button--info button--accept'>
                                Nhận
                            </RippleButton>
                        : selected ?
                            <RippleButton moreclass='button button--disabled button--reject'>
                                Hủy 
                            </RippleButton> 
                        : null
                        } 
                    </div>
                }*/}
    </Fragment>

const getFirstPoint = listPoints => !listPoints ? null : shortLocation(listPoints[0].text || '');

const getListPoints = listPoints => !listPoints ? null : listPoints.map(item => item.text).join('...');
    
    
const Avatar = ({name, image, small}) => 
    <Fragment>
        {image ? <img src='http://goo.gl/9iitr6' className='avatar avatar--small'/> :
        <span className={`avatar ${small ? 'avatar--small' : ''}`}> 
            {lastNameFirstLetter(name)}
        </span> 
        }
    </Fragment>

const lastNameFirstLetter = (name='') => {
    return name.toUpperCase().split(' ').slice(-1)[0][0];
}
const formatPrice = (price=0) => {
    return price.toLocaleString()+' VNĐ';
}
const formatDistance = (distance=0) => {
    return distance > 100 ? (Math.round(distance/100) / 10).toLocaleString()+' km' : distance.toLocaleString()+' m';
}
export default CarReservationItem;