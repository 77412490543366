import React, { Fragment } from 'react';

export const SelectControl = ({value='', placeholder='', options=[], className='', icon, onChange}) => {
	return (
		<div className={`nb-form-control ${className}`}>
			{icon && 
				<i className={`nb-form-control__icon ${icon}`}></i>
			}
			<select value={value}
				className={`nb-form-control__input`} 
				onChange={e=>onChange(e.target.value)}>
				{placeholder && 
					<option disabled value=''>{placeholder}</option>
				}
				{options.map(option=>
					<option value={option.value} key={option.value}>
						{option.text}
					</option>
				)}
			</select>
		</div>
	)
}