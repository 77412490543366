import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setForm } from '../Actions/BookingForms.action';
import { directionOptions, carOptions } from '../Core/Constants';
import { findDirection, isClip, convertDistance, calculatePostage, convertTravelTime, is0 } from '../Core/AppUtils';
import { rule, I18nCalendar, routeTypeOptions } from '../Core/Constants';
import { shortLocation, overTime, listPointsToHash, transfrom } from '../Core/AppUtils';
import { Tip } from '../Components/Tip';
const moment = require('moment');

const mapStateToProps = state => {
    return {
        bookingForm : state.bookingForm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setForm: (key,value, callback) => dispatch(setForm(key, value)).then(callback),
    }
}

let oldHash = null;
class PricePreviewForm extends Component {
	constructor(props) {
        super(props);
        [
        	'calculateDirection',
        ].forEach(fn => this[fn] = this[fn].bind(this));
    }

    calculateDirection() {
        const {bookingForm, setForm} = this.props;
        const {listPoints, direction, distance} = bookingForm;
        const newHash = listPointsToHash(transfrom(direction)(listPoints));
        if (distance && oldHash && oldHash===newHash) {
            return;
        }
        oldHash = newHash;
        const setResult = (({distance,duration, detail})=>{
            distance && setForm('distance', distance);
            duration && setForm('duration', duration);
            detail && setForm('detail', detail);
        });
        findDirection([...listPoints], direction, setResult);
    }

    componentDidUpdate() {
        this.calculateDirection();
    }

    render() {
    	const {bookingForm, setForm} = this.props;
    	const {
            listPoints, 
            car, 
            direction, 
            selectedDateTime, 
            discount=0,
            distance=0, 
            duration=0,
            routeType,
            driverName,
            detail,
            withBoardName,
            tip,
        } = bookingForm;
        const carText = (carOptions.find(opt=>opt.value===car) || {}).text;
        const directionText = directionOptions.find(opt=>opt.value===direction).text;
        const routeDistance = convertDistance(distance);
        const routeTime = convertTravelTime(duration);
        const sum = calculatePostage(bookingForm);
        const setTip = tip => setForm('tip', tip);
    	return (
            <Fragment>
                {routeType === routeTypeOptions['provice-provice'] &&
                    <center><p>
                        Giá đi tỉnh chưa bao gồm phí cầu đường<br/>
                    </p></center>
                }
        		<div className={`route__preview`}>
        			<div className='route__preview-element'>
        				Quãng đường: <span className='route__preview--big'>{routeDistance} </span>
        			</div>
        			<div className='route__preview-element'>
        				Thời gian: <span className='route__preview--big'>{routeTime}</span>
        			</div>
        			<div className='route__preview-element'>
        				Giá tiền: <span className='route__preview--big'>{sum} VNĐ</span> 
        			</div>
        		</div>
                <Tip {...{tip, setTip}}/>
            </Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PricePreviewForm);