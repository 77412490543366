export const CAR_RESERVATION = {
    '_LOAD' : 'CAR_RESERVATION_LOAD',
    '_LOAD_ERROR' : 'CAR_RESERVATION_LOAD_ERROR',
    '_RECEIVE' : 'CAR_RESERVATION_RECEIVE',
    '_SELECT' : 'CAR_RESERVATION_SELECT'
}

export const loadError = error => ({
    type: CAR_RESERVATION._LOAD_ERROR,
    payload: {
        error,
    }
})

export const receive = carReservationList => ({
    type: CAR_RESERVATION._RECEIVE,
    payload: {
        carReservationList,
    },
})
