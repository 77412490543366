import { combineReducers } from 'redux';
import { carReservations } from './CarReservation.reducer';
import { bookingForm } from './BookingForm.reducer';
import { notifications } from './Notifications.reducer';
import { auth } from './Auth.reducer';
import { bookedTrips } from './BookedTrip.reducer';

const app = combineReducers({
    bookingForm,
    notifications,
    carReservations,
    auth,
    bookedTrips,
});

export default app;