import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { InputControl } from '../Controls/Input.control';
import { setForm } from '../Actions/BookingForms.action';
import { validateMobileNumber, validateEmail } from '../Core/AppUtils';
import { setUserInfo, getUserInfo } from '../Core/StorageUtils';

const mapStateToProps = state => {
    return {
        name : state.bookingForm.name, 
        tel : state.bookingForm.tel, 
        email : state.bookingForm.email, 
        comment : state.bookingForm.comment, 
        bookingForm : state.bookingForm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setForm: (key,value, callback) => dispatch(setForm(key, value)).then(()=>callback&&callback()),
    }
}

class ContactBookingForm extends Component {
	constructor(props) {
        super(props);
        this.state = {
            saveUser : false,
        };
        [
            'preSetForm',
            'toggleSaveUser',
        ].forEach(fn => this[fn] = this[fn].bind(this));
    }
    toggleSaveUser() {
        const saveUser = !this.state.saveUser;
        this.setState({saveUser});
        if (saveUser) {
            const {name, tel, email} = this.props;
            setUserInfo({name, tel, email});
        }
    }
    componentDidMount(){
        //this.calculateDirection();
        const {name, tel, email} = getUserInfo();
        //console.log(getUserInfo());
        this.preSetForm('name')(name);
        this.preSetForm('tel')(tel);
        this.preSetForm('email')(email);
    }
    preSetForm(key) {
        return value => {
            const {setForm} = this.props;
            const callback = () => {
                const {name, tel, email, verify,setErrorMessage} = this.props;
                let error = '';
                if (!name) {
                    error = 'Xin hãy điền tên';
                } else if (!tel) {
                    error = 'Xin hãy điền số điện thoại';
                } else if (!email) {
                    error = 'Xin hãy điền email';
                } else if (!validateMobileNumber(tel)) {
                    error = '*Số chưa hợp lệ';
                } else if (!validateEmail(email)) {
                    error = '*Email chưa hợp lệ';
                } 
                setErrorMessage(error);
                verify(!error);
            }
            setForm && setForm(key, value, callback);
        }
    }
    render() {
        const {name: saveName, tel: saveTel, email: saveEmail} = getUserInfo();
        const {name, tel, email, comment, setForm} = this.props;
        const {saveUser} = this.state;
    	return (
            <Fragment>
                <h4 className='text-center step__title'>Thông tin cá nhân</h4>
                <InputControl value={name}
                    icon='nb-form-control__icon--person'
                    placeholder='Họ tên* ...'
                    onChange={this.preSetForm('name')}
                    />
                <InputControl value={tel}
                    icon='nb-form-control__icon--phone'
                    placeholder='Số điện thoại* ... '
                    type='tel'
                    onChange={this.preSetForm('tel')}
                    />
                <InputControl value={email}
                    icon='nb-form-control__icon--email'
                    placeholder='Email ...'
                    type='email'
                    onChange={this.preSetForm('email')}
                    />
                <div className="nb-form-control">
                    <i className="nb-form-control__icon nb-form-control__icon--edit"></i>
                    <textarea className="nb-form-control__input nb-form-control__input--textarea" 
                        value={comment} 
                        placeholder='Ghi chú ... '
                        onChange={e=>this.preSetForm('comment')(e.nativeEvent.target.value)}></textarea>
                </div>
                <div className="nb-form-control">
                    <label>
                        <input type='checkbox' checked={saveUser} onChange={e=> this.toggleSaveUser()}/>
                        &nbsp; Lưu thông tin
                    </label>
                </div>
            </Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactBookingForm);