import React, { Component, Fragment } from 'react';
import { RippleButton } from '../Components/RippleButton';
import { submit } from '../Actions/BookingForms.action';

export const StepPanel = (props) => props;

export class Steps extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
       const {setMaxStep, children} = this.props;
        const maxStep = React.Children.toArray(this.props.children).length;
        setMaxStep(maxStep);
    }
    render() {
        const {children, stepIndex, nextStep, final, resetStep, endStep, setStep} = this.props;
        const steps = React.Children.toArray(this.props.children);
        const currentProps = steps ? steps[stepIndex].props||null : null;
        const normal = {
            text: 'Tiếp Theo',
            action: nextStep,
        }
        const isFinal = stepIndex===steps.length-1
        const footerProps = {
            currentProps,
            isFinal, 
            normal, 
            final,
        }
        return (
            <section className='step'>
                <nav className='step__header-container'>
                    {steps && steps.map((each, index)=>
                        <Fragment key={`step__header-${index}`}>
                            {index!==0 && 
                                <div className={`step__header-delimiter ${stepIndex>=index?'active':''}`}></div>
                            }
                            <div className={`step__header 
                                ${each.props.icon||''} 
                                ${stepIndex>=index?'active':''}`}></div>
                        </Fragment>
                    )}
                </nav>
                <main className='step__panels-container'>
                    {steps && steps.map((each, index, arr)=>
                        <div key={`step__panels${index}`}
                            className={`step__panel 
                                ${index===stepIndex?'active':''}`}>
                            {(index===stepIndex) && each.props.children} 
                        </div>
                    )}
                </main>
                <footer className='step__footer'>
                    <FooterContent {...footerProps}/>
                    {isFinal ?
                        <div className='step__footer-row'> 
                            {/*Thông tin chưa đúng ? &nbsp; 
                            <a className='step__reset' onClick={e=>setStep(0)}>Sửa thông tin
                            </a>*/}
                            <a className='step__end' onClick={e=>endStep()}>
                                Hủy đặt chuyến
                            </a>                          
                        </div>
                   :    
                       <div className='step__footer-row'> 
                            <a className='step__end' onClick={e=>endStep()}>
                                Hủy đặt chuyến
                            </a>
                        </div>
                    }
                </footer>
            </section>
        )
    }
}

const FooterContent = ({currentProps, isFinal, normal, final}) => 
    !currentProps ? null : 
    !currentProps.valid ? <span className='step__footer-warning'> {currentProps.footer} </span>:
    !isFinal ? <Button {...normal}/> :
    <Button {...final} />

const Button = ({text, action}) => 
    <div className='button step__button button--info button--compact' 
        onClick={e=>action()}>
        <RippleButton >
            {text}
        </RippleButton>
    </div>