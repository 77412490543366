import React, { Component, Fragment } from 'react';
import { I18nCalendar } from '../Core/Constants';

const _moment = require('moment');
_moment.locale('vn', {
    week: {
        dow: 7
    }
});
const moment = (value, format=I18nCalendar.formatDate) => !value ? _moment() : _moment(value,format);

/*split array into equal length chunk*/
const chunk = (r,j) => r.reduce((a,b,i,g) => !(i % j) ? a.concat([g.slice(i,i+j)]) : a, []);

export class Calendar extends Component {
	constructor(props) {
        super(props);
        const {
            startDate = moment().startOf('day').toDate(), 
            endDate= moment().add(1,'month').toDate(),
            selectedDateTime = moment().startOf('day').toDate(),
            datesOff = [],
        } = props;
        const thisTime = selectedDateTime ? moment(selectedDateTime, I18nCalendar.formatDateTime).toDate() : moment().startOf('day').toDate();
        this.state = {
            startDate,
            endDate,
            focusDate: thisTime,
            selectedDate: thisTime,
            compactMode: false,
            datesOff,
        };
        [
            'setDate',
            'toggleCompactMode',
            'prevFn',
            'nextFn',
            'getDisplayWeek',
            'addClass',
            'reset',
            'userSetDate'
        ].forEach(fn => this[fn] = this[fn].bind(this));
    }
    setDate(date) {
        if (moment(date).isBefore(this.state.startDate)) date = this.state.startDate;
        if (moment(date).isAfter(this.state.endDate)) date = this.state.endDate;
        this.setState((prevState, props) => ({
            ...prevState,
            focusDate: date,
        }));
    }
    userSetDate(date) {
        this.setState((prevState, props) => ({
            ...prevState,
            selectedDate: date,
        }),()=>{
            const {onChange} = this.props;
            onChange && onChange(date);
        });
    }
    toggleCompactMode() {
        /*this.setState((prevState, props) => ({
            ...prevState,
            compactMode: !prevState.compactMode,
        }));*/
    }
    reset() {
        this.setDate(moment().startOf('day').toDate());
    }
    prevFn() {
        if (this.state.compactMode === true) {
            this.setDate(moment(this.state.focusDate).subtract(1,'week').startOf('day').toDate())
        } else {
            this.setDate(moment(this.state.focusDate).subtract(1,'month').startOf('day').toDate())
        }
    }
    nextFn() {
        if (this.state.compactMode === true) {
            this.setDate(moment(this.state.focusDate).add(1,'week').startOf('day').toDate())
        } else {
            this.setDate(moment(this.state.focusDate).add(1,'month').startOf('day').toDate())
        }
    }
    addClass(date) {
        const {focusDate, selectedDate, startDate, endDate, datesOff} = this.state;
        const className = [
            'date__item',
            Object.keys(I18nCalendar.dayName)[moment(date).day()],
            ...(moment(date).month() !== moment(focusDate).month() ? ['faded'] : []),
            ...(moment(date).isSame(selectedDate, 'day') ? ['date__item--selected'] : []),
            ...(moment(date).isSame(moment(), 'day') ? ['date__item--today'] : []),
            ...(!moment(date).isBetween(startDate, endDate, 'days', '[]') || (datesOff.indexOf(moment(date).format(I18nCalendar.dateFormat))>=0) ? ['date__item--disabled'] : []),
        ].join(' ');
        return {
            date: date.toDate(),
            className: className,
        }
    }
    getDisplayWeek() {
        const {focusDate} = this.state;
        let displayDate = [];
        const startWeekDay = moment(focusDate).startOf('week'),
            endWeekDay = moment(focusDate).endOf('week'),
            startCalendarDay = moment(focusDate).startOf('month').startOf('week'),
            endCalendarDay = moment(focusDate).endOf('month').endOf('week');
        for (let d = moment(startCalendarDay); d.isBefore(endCalendarDay); d.add(1,'day')) {
            displayDate.push(this.addClass(d));
        }
        const currentWeekIndex = (moment(focusDate).diff(startCalendarDay, 'days')/7) | 0;
        const displayWeeks = chunk(displayDate, 7);
        return {
            displayWeeks,
            currentWeekIndex,
        }
    }
    render() {
        const headerProps = {
            ...this.state,
            prevFn: this.prevFn,
            nextFn: this.nextFn,
            toggleCompactMode: this.toggleCompactMode, 
            reset: this.reset,
        }
        const bodyProps = {
            setDate: this.setDate,
            userSetDate: this.userSetDate,
            ...this.getDisplayWeek(),
        }
    	return (
            <section className={`calendar ${!this.state.compactMode?'calendar--full-mode':'calendar--compact-mode'}`}>
                <CalendarHeader {...headerProps}/>
                <Month {...bodyProps}/>
            </section>
		)
    }
}

const CalendarHeader = ({ focusDate, compactMode, prevFn, nextFn, toggleCompactMode, reset}) => 
    <Fragment>
        <div className='date__row calendar__header'>
            <div className='calendar__prev' onClick={e=>prevFn()}></div>
            <div className='calendar__reset'></div>
            <div className={`calendar__header-title`} onClick={e=>toggleCompactMode()}>
                <strong>{I18nCalendar.monthNames[moment(focusDate).month()]}</strong>
                 -{moment(focusDate).year()}
            </div>
            <div className='calendar__reset' onClick={e=>reset()}>{I18nCalendar.today}</div>
            <div className='calendar__next' onClick={e=>nextFn()}></div>
        </div>
        <div className='date__row date__name'>
            {Object.values(I18nCalendar.dayName).map((item, index)=>(
                <div className="date__column" key={`I18nCalendar.dayName-${index}`}>
                    <span className='date__item date__header'>{item}</span>
                </div>
            ))}
        </div>
    </Fragment>

const Month = ({displayWeeks, currentWeekIndex, setDate, userSetDate}) => {
    return (
        <Fragment>
            {displayWeeks.map((week, index) => 
                <div className={`date__row has-border ${index!=currentWeekIndex?'compact--hide':''}`} key={`week-${index}`}>
                    <Week week={week} setDate={setDate} userSetDate={userSetDate}/>
                </div>    
            )}
        </Fragment>    
    )
}

const Week = ({week, setDate, userSetDate}) => 
    week.map((item, index)=>(
        <div className="date__column" key={`day-${index}`} >
            <span className={`${item.className}`} onClick={e=>userSetDate(item.date)} >
                {item.date.getDate()}
            </span>
        </div>
    ));