import React, { Fragment } from 'react';
import { fireEventScroll } from '../Core/AppUtils';

export const InputControl = ({value='', placeholder='', type='text', className='', icon, onChange, error}) => {
	return (
		<div className={`nb-form-control ${className}`}>
			{icon && 
				<i className={`nb-form-control__icon ${icon}`}></i>
			}
			<input placeholder={placeholder} 
				className={`nb-form-control__input`} 
				value={value} 
				type={type} 
				onFocus={e=> fireEventScroll(e.target)}
				onChange={e=>onChange(e.nativeEvent.target.value)}/>
			{value && 
				<i className={`nb-form-control__icon nb-form-control__icon--reset`} onClick={e=>onChange('')}></i>
			}
			{error && 
				<div className='nb-form-control__error'>
					{error}
				</div>
			}
		</div>
	)
}