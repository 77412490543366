import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DateTimeControl } from '../Controls/DateTime.control';
import { SelectControl } from '../Controls/Select.control';
import { InputControl } from '../Controls/Input.control';
import { setForm } from '../Actions/BookingForms.action';
import { directionOptions, carOptions, routeTypeOptions } from '../Core/Constants';
import Select from 'react-select';

const mapStateToProps = state => {
	return {
		bookingForm: state.bookingForm,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setForm: (key,value, callback) => dispatch(setForm(key, value)).then(callback),
	}
}

class ServiceBookingForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		[
			'preSetForm',
			'validate',
		].forEach(fn => this[fn] = this[fn].bind(this));
	}
	validate() {
		const {bookingForm, verify} = this.props;
		const {direction, car, selectedDateTime, flightCode} = bookingForm;
		verify && verify(!!direction && !!car && !!selectedDateTime);
	}
	preSetForm(key) {
		return value => {
			const {setForm} = this.props;
			setForm && setForm(key, value, this.validate);
		}
	}
	componentDidMount(){
		this.validate();
	}
	render() {
		const {bookingForm, setForm} = this.props;
		const {direction, car, selectedDateTime, flightCode, withBoardName, routeType} = bookingForm;
		const toggleWithBoardName = this.preSetForm('withBoardName');
		return (
			<Fragment>

				<DateTimeControl icon='calendar' 
					placeholder='Thời gian đón* ...' 
					onChange={this.preSetForm('selectedDateTime')} 
					selectedDateTime={selectedDateTime}/>

				<div className='nb-form-control__group'>
					<SelectControl value={car}
						className='nb-form-control__group--expand'
						icon='nb-form-control__icon--car'
						options={carOptions}
						onChange={this.preSetForm('car')}
						placeholder='Chọn loại xe* ...'/>

					<SelectControl value={direction}
						className='nb-form-control__group--expand'
						icon='nb-form-control__icon--direction'
						options={directionOptions}
						onChange={this.preSetForm('direction')}
						placeholder='Chọn chiều di chuyển* ...'/>
				</div>

				{routeType!==routeTypeOptions['provice-provice'] && 
					<div className='nb-form-control__group'>
						
						<InputControl value={flightCode}
						className='nb-form-control__group--expand'
						icon='nb-form-control__icon--flight'
						placeholder='Mã chuyến bay ...'
						onChange={this.preSetForm('flightCode')}/>

						<div className='nb-form-control nb-form-control__group--expand'>
							<label>
								<input type='checkbox' checked={withBoardName} onChange={e=> toggleWithBoardName(!withBoardName)}/>
								Đón với bảng tên
							</label>
						</div>
					</div>
				}
				

				
			</Fragment>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceBookingForm);