import { CAR_RESERVATION } from '../Actions/CarReservation.action';

export const carReservations = (state = { carReservationList:{} }, action) => {
    switch (action.type) {
        case CAR_RESERVATION._RECEIVE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}