import { NOTIFICATION_SET } from '../Actions/Notifications.action';

const defaultState = {
	booking: {},
	picking: {},
}
export const notifications = (state = defaultState, action) => {
		const {type, payload} = action;
		if (!payload) return state;
		const {key, notification} = payload;
        if (!key || !notification) return state;
		const {message, icon} = notification;
    switch (type) {
        case CAR_RESERVATION._RECEIVE:
            return {
                ...state,
                notification: {
                	...state.notification,
                	[key] : notification,
                }
            };
        default:
            return state;
    }
}