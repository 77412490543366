import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import { deferedPromise } from './Core/AppUtils';
import { addLocalTripId, removeLocalTripId } from './Core/StorageUtils';

const firebaseOption = {
	apiKey: 'AIzaSyCdMOOC4yyY9-KXipeMvyAk5GVFvNmto10',
	authDomain: 'datxetaxi-ac27b.firebaseapp.com',
	databaseURL: 'https://datxetaxi-ac27b.firebaseio.com',
	projectId: 'datxetaxi-ac27b',
	storageBucket: 'datxetaxi-ac27b.appspot.com',
	messagingSenderId: '343651525049'
}
const app = firebase.initializeApp(firebaseOption);

const firestore = app.firestore();

/*
const messaging = firebase.messaging();
messaging.usePublicVapidKey('BJqrYEY2Dc7r4_ZB7Yqd5bJ6lsZoQY7hM8JjvilYbxoOFcdRZR-V8Im1ZX2AQ1So_DC_7ySF3kNTbwW2FDT4Ges');
*/

const database = app.database();
const refTrips = database.ref('/trips');
const refOldTrips = database.ref('/oldtrips');
const refTokens = database.ref('/tokens');

let messageToken ;

/* PUSH MESSAGE TOKEN*/
const userDefered = deferedPromise();
const messageTokenDefered = deferedPromise();
Promise.all([userDefered.promise, messageTokenDefered.promise]).then(values => { 
  const [user, messageToken] = values;
  subcribeNewTrip(user, messageToken);
});
const subcribeNewTrip = (user, messageToken) => {
  const userId = user.user.uid;
  const userRef = refTokens.child(userId);
  userRef.once('value', (snapshot)=> {
    const oldToken = snapshot.val();
    if (oldToken!=messageToken) {
      userRef.set(messageToken);
    }
  })
}

/*LOGIN*/
export const firebaseLogin = (email, password, onError, onSuccess) => {
  return firebase.auth().signInWithEmailAndPassword(email,password)
    .catch(error => {
      onError(error);
    })
    .then(user => {
      onSuccess(user);
      userDefered.resolve(user);
    });
}

/*TRIP*/
export const addBooking = (bookingForm, callback) => {
  const newTripRef = refTrips.push();
  const newTripId = newTripRef.key;
  const trip = {
    id: newTripId,
    ...bookingForm,
    ...(messageToken? {messageToken}: {}),
  }
  newTripRef.set(trip);
  addLocalTripId(trip.id);
  return new Promise((resolve,reject)=> resolve(trip));
  /*callback && callback(newTripId);*/
}

export const removeBooking = (id) => {
  if (id) {
    refTrips.child(id).remove();
  }
  removeLocalTripId(id);
}

export const initTrip = ({onTripLoad, onTripAdd, onTripRemove, onTripUpdate}) => {
  let first = true;
  refTrips.once('value', snapshot => {
    const trips = snapshot.val();
    for(let id in trips) {
      trips[id] = {...trips[id], id}
    }
    onTripLoad(trips);
  });
  refTrips.limitToLast(1).on('child_added', (snapshot, prevChildKey) => {
    if (!first) {
      /*console.log(snapshot.val(), prevChildKey);*/
      onTripAdd({...snapshot.val(), id:snapshot.key});
    }
    first = false;
  });
  refTrips.on('child_removed', (snapshot, prevChildKey) => {
    /*console.log(snapshot.val(), prevChildKey);*/
    onTripRemove({...snapshot.val(), id:snapshot.key});
  });
  refTrips.on('child_changed', (snapshot, prevChildKey) => {
    /*console.log(snapshot, snapshot.key, snapshot.val(), prevChildKey);*/
    onTripUpdate({...snapshot.val(), id:snapshot.key});
  });
}

export const takeTrip = (trip, driver) => {
  if (!trip || !driver) return;
  const {driverId, driverName, driverTel} = driver;
  refTrips.child(trip.id).update({
    driverId, 
    driverName,
    driverTel: driverTel || '123',
  });
}

export const cancelTrip = (trip, driver) => {
  if (!trip || !driver) return;
  if (trip.driverId !== driver.driverId) return;
  refTrips.child(trip.id).update({
    driverId : '', 
    driverName : '', 
    driverTel: '',
  });
}

export const completeTrip = (trip, driver) => {
  refTrips.child(trip.id).remove();
  refOldTrips.child(trip.id).set(trip);
}

export const getBookedTrips = bookedTripIds => bookedTripIds.map(id=> refTrips.child(id).once('value').then(snapshot => snapshot.val()));

/*MESSAGES*/

export const messageInitialize = () => {
  // [requestPermission on web]
  messaging.requestPermission()
    .catch((err) => {
      console.log('Unable to get permission to notify.', err);
    })
    .then(() => {
      console.log('Notification permission granted.');
    });
  // [get message when app is focus]
  messaging.onMessage((payload) => {
    appendMessage(payload);
  });
  // [refresh token]
  messaging.onTokenRefresh(() => {
    requestToken('refresh');
  });
  // [first token]
  requestToken();
}

const requestToken = (task) => {
  messaging.getToken()
    .catch((err) => {
      console.log(`Unable to get ${task} token`, err);
    })
    .then((token) => {
      console.log('Get token successful ', token);
      messageToken = token;
      messageTokenDefered.resolve(token);
    });
}

const appendMessage = (payload) => {
  console.log('Message received. ', payload);
  makeNotification(payload.data);
}

export const makeNotification = (options) => {
  if (!options) return null;
  var notification = new Notification(options.title, options);
  notification.onclick = function(x) { 
    window.focus();
    this.close(); };
  return notification;
}

//messageInitialize();

export { database, firestore };


