export const TRIP_IDS = 'TRIP_IDS';
export const TRIPS = 'TRIPS';
export const USER = 'USER';

export const setLocalStorage = (key, val) => {
	localStorage.setItem(btoa(key), btoa(JSON.stringify(val)));
}

export const getLocalStorage = (key) => {
	const temp = localStorage.getItem(btoa(key));
	return temp ? JSON.parse(atob(temp)) : null ;
}

export const setLocalTripIds = (tripIds=[]) => setLocalStorage(TRIP_IDS, tripIds);

export const getLocalTripIds = () => getLocalStorage(TRIP_IDS) || [];

export const addLocalTripId = id => {
	const key = TRIP_IDS;
	const currentValue = getLocalStorage(key) || [];
	if (currentValue.includes(id)) {
		return;
	}
	const newValue = [
		...currentValue,
		id,
	];
	setLocalStorage(key, newValue);
}

export const removeLocalTripId = id => {
	const key = TRIP_IDS;
	const currentValue = getLocalStorage(key) || [];
	const newValue = currentValue.filter(each=>each!==id);
	setLocalStorage(key, newValue);
}

export const setUserInfo = (userInfo={}) => setLocalStorage(USER, userInfo);

export const getUserInfo = () => getLocalStorage(USER) || {};