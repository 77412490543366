import { SET_USER } from '../Actions/Auth.action';

const defaultState = {
	user: {},
}
export const auth = (state = defaultState, action) => {
	const {type, payload} = action;
	if (!payload) return state;
	const {user} = payload ;
  switch (action.type) {
      case SET_USER:
        return {
            ...state,
            user,
        };
      default:
          return state;
  }
}