import React, { Fragment } from 'react';

export const RippleButton = (props) => {
    function handleClick(e) {
        e = e.nativeEvent;
        let ele = e.target;
        ele.classList.remove('animate');
        ele.style.cssText = `--ripple-top: ${e.offsetY - ele.offsetWidth/2}px; --ripple-left: ${e.offsetX - ele.offsetWidth/2}px;`;
        ele.classList.add('animate');
        props.handleClick && props.handleClick();
    }
    return (
        <span className={`btn-ripple ${props.className||''}`} {...props} onClick={e => handleClick(e)} >
        </span>
    )
}