import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';

import { Provider } from 'react-redux';
import { applyMiddleware, bindActionCreators, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import app from './Reducers/reducers';

import AppBookingContainer from './Containers/AppBooking.container';

import { addBookedTrip, removeBookedTrip} from './Actions/BookedTrip.action';
import {database, firestore, messaging} from './Firebase';
import { getLocalTripIds } from './Core/StorageUtils';

const preloadState = {
};
const store = createStore(
  app, 
  preloadState, 
  composeWithDevTools(applyMiddleware(thunk))
);
const reduxAction = bindActionCreators({ addBookedTrip,
	removeBookedTrip,
} , store.dispatch);

if (document.getElementById('app')) {
    ReactDOM.render(
        <Provider store={store}>
          <AppBookingContainer/>
        </Provider>,
        document.getElementById('app')
    );
}