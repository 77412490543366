import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { PlaceControl } from '../Controls/Place.control';
import { setForm } from '../Actions/BookingForms.action';
import { or0, isClip, airPortIndex, getRouteType } from '../Core/AppUtils';
import { milestones, routeTypeOptions } from '../Core/Constants';

const mapStateToProps = state => {
    return {
        bookingForm : state.bookingForm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setForm: (key, value, callback) => dispatch(setForm(key, value)).then(callback),
    }
}

class RouteBookingForm extends Component {
	constructor(props) {
        super(props);
        this.state = {
            listPoints : props.bookingForm.listPoints,
        };
        [
            'setPlace',
            'reversePlace',
            'addAirPort',
            'removeAirPort',
            'verifyRoute',
        ].forEach(fn => this[fn] = this[fn].bind(this));
    }
    
    addAirPort() {
        const {listPoints} = this.state;
        const index = airPortIndex(listPoints);
        if (index>-1) return;
        const airport = { text: milestones.NoibaiAirport.text };
        
        const placeIndex = !listPoints[1] ? 1 : null;
        this.setPlace(placeIndex)(airport);
    }
    removeAirPort() {
        const {listPoints} = this.state;
        const index = airPortIndex(listPoints);
        if (index<0) return;
        this.setPlace(index)('');
    }
    /*airPortIndex(listPoints) {
        for(let i in listPoints) {
            const point = listPoints[i];
            if (point) {
                const text = point.text.toLowerCase();
                if (text.indexOf('noi bai') > -1 || text.indexOf('nội bài') > -1) {
                    return +i;
                }
            }
        }
        return -1;
    }*/
    setPlace(index) {
        return value => {
            const {setForm, verify} = this.props;
            const {listPoints} = this.state;
            let newListPoints;
            if (!index) {
                const avaiableIndex = listPoints.findIndex(each=>!each || !each.text);
                if (avaiableIndex>-1) {
                    index = avaiableIndex;
                }
            }
            if (or0(index) && isClip(index, 0, listPoints.length-1)) {
                newListPoints = [
                    ...listPoints.slice(0,index), 
                    ...(value || value==="" ? [value] : []), 
                    ...listPoints.slice(index+1)
                ].filter((each, index)=>index < 2 || each && each.text);
            } else {
                newListPoints = [...listPoints, value];
            }
            const callback = () => {
                this.verifyRoute();
            }
            setForm('listPoints', newListPoints, callback);
        } 
    }

    verifyRoute() {
        const {setForm, verify, bookingForm} = this.props;
        const {listPoints} = bookingForm;
        verify && verify(listPoints && listPoints.filter(each=>each && each.text).length>=2);
    }

    reversePlace() {
        const {setForm, verify} = this.props;
        const {listPoints} = this.state;
        let newListPoints = [...listPoints].reverse();
        const callback = () => {
            this.verifyRoute();
        }
        setForm('listPoints', newListPoints, callback);
    }

    componentWillReceiveProps(nextProps) {
        const {bookingForm, verify, setForm} = nextProps;
        const {listPoints} = bookingForm;
        const stringtify = listPoints => listPoints.map(point=>point ? point.text : '' ).join(',');
        if (stringtify(this.state.listPoints)!==stringtify(listPoints)) {
            this.setState((prevState, props)=> ({listPoints})); 
        };
        const routeType = getRouteType(this.state.listPoints);
        if (routeType!= nextProps.bookingForm.routeType) {
            setForm && setForm('routeType', routeType, ()=>{});
        }
    }

    render() {
        const {firstPoint, lastPoint, setForm, bookingForm } = this.props;
        const {routeType} = bookingForm;
        const {listPoints} = this.state;
        const routeHeaderProps = {
            hasAirPort: airPortIndex(listPoints)>-1,
            addAirPort: this.addAirPort,
            removeAirPort: this.removeAirPort,
        }
    	return (
            <Fragment>
                <RouteHeader {...routeHeaderProps}/>
                {listPoints.map((point, index, arr)=>
                    <PlaceControl icon={index<arr.length-1?'trip':'place'}
                        placeholder={index===0 ? 'Nhập điểm đón: Nhà riêng, Khách sạn, Sân bay ... ' : 'Nhập điểm đến: Nhà riêng, Khách sạn, Sân bay... '}
                        value={point} 
                        setPlace={this.setPlace(index)}
                        required={index<2}
                        key={`place-${index}`}/>
                )}
                {listPoints.length < 10 &&
                    <div className='nb-form-control__group'>
                        <PlaceControl 
                            className='nb-form-control__group--expand'
                            icon='more-place' 
                            placeholder='Thêm lộ trình ... ' 
                            name='lastPoint' 
                            setPlace={this.setPlace()}/>
                        <div className="nb-form-control">
                            <span className="notification--red">{/* Tips: nhập địa chỉ để xem giá cước */}</span>
                            <i className="nb-form-control__icon nb-form-control__icon--swap"></i>
                            <a className='route--link' onClick={()=>this.reversePlace()}>Đảo chiều</a>
                        </div>
                    </div>
                }
                
            </Fragment>
        )
    }
}

const RouteHeader = ({hasAirPort, addAirPort, removeAirPort}) =>
    <div className='route__tab-container'>
        <div onClick={()=>addAirPort()}
             className={`route__tab ${hasAirPort? 'route__tab--active' : ''}`}>
            <i className="nb-form-control__icon nb-form-control__icon--flight"></i>
            <span className='route__header'>NỘI BÀI</span>
        </div>
        <div onClick={()=>removeAirPort()}
             className={`route__tab ${!hasAirPort? 'route__tab--active' : ''}`}>
            <i className="nb-form-control__icon nb-form-control__icon--landscape    "></i>
            <span className='route__header'>ĐƯỜNG DÀI</span>
        </div>
    </div>

export default connect(mapStateToProps, mapDispatchToProps)(RouteBookingForm);